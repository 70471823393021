import React from 'react';

import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from 'ra-language-spanish';
import { createMuiTheme } from '@material-ui/core/styles';

import { restClient, authClient } from 'ra-data-feathers';
import feathersClient from './feathers';

import UsersIcon from '@material-ui/icons/People';
import { UserList, UserEdit, UserCreate } from './routes/users';

import AccountsIcon from '@material-ui/icons/AccountBox';
import { AccountList, AccountEdit, AccountCreate } from './routes/accounts';

import ConfigsIcon from '@material-ui/icons/List';
import { ConfigsList, ConfigsEdit, ConfigsCreate } from './routes/configs';

import EndpointsIcon from '@material-ui/icons/CallSplit';
import { EndpointsList, EndpointsEdit, EndpointsCreate } from './routes/endpoints';

import DebugsIcon from '@material-ui/icons/BugReport';
import { DebugsList, DebugsEdit } from './routes/debugs';

const i18nProvider = polyglotI18nProvider(() => spanishMessages, 'es');
const restClientOptions = {
  id: 'id',
  usePatch: true
};
const authClientOptions = {
  usernameField: 'email',
  redirectTo: '/login', // Our example login form might be at '/signin', redirect here if AUTH_CHECK fails
  storageKey: 'accessToken',
  authenticate: {
    strategy: 'local'
  },
  logoutOnForbidden: true
}
const theme = createMuiTheme({
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
  },
});

function App() {
  return (
    <div className="App">
      <Admin
        title='IntegroPOS Proxy Admin'
        theme={theme}
        locale="es"
        i18nProvider={i18nProvider}
        dataProvider={restClient(feathersClient, restClientOptions)}
        authProvider={authClient(feathersClient, authClientOptions)}
      >
        <Resource name="users" icon={UsersIcon} list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="accounts" icon={AccountsIcon} list={AccountList} edit={AccountEdit} create={AccountCreate} />
        <Resource name="endpoints" icon={EndpointsIcon} list={EndpointsList} edit={EndpointsEdit} create={EndpointsCreate} />
        <Resource name="debugs" icon={DebugsIcon} list={DebugsList} edit={DebugsEdit} />
        <Resource name="configs" icon={ConfigsIcon} list={ConfigsList} edit={ConfigsEdit} create={ConfigsCreate} />
      </Admin>
    </div>
  );
}

export default App;
