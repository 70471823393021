import React from 'react';
import { List, Edit, Create, Filter, Datagrid, TextField, EmailField, DateField, SimpleForm, TextInput, DateInput } from 'react-admin';

const AccountFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);

export const AccountList = props => (
    <List filters={<AccountFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="extra_info" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);

export const AccountCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="extra_info" multiline />
        </SimpleForm>
    </Create>
);

export const AccountEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="extra_info" multiline />
            <DateInput source="createdAt" />
            <DateInput source="updatedAt" />
        </SimpleForm>
    </Edit>
);
