import React from 'react';
import { List, Edit, Create, Filter, Datagrid, ReferenceField, TextField, UrlField, DateField, BooleanField, SimpleForm, BooleanInput, TextInput, SelectInput, ReferenceInput } from 'react-admin';

const endpoints_list = [
    { value: 'ubereats', name: 'UberEats' },
    { value: 'rappi', name: 'Rappi' },
    { value: 'didi', name: 'Didi-Food' }
]

const EndpointsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Account" source="account_id" reference="accounts">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="from" choices={endpoints_list} optionText="name" optionValue="value" />
        <TextInput label="From ID" source="from_id" alwaysOn />
        <TextInput label="To" source="to_url" alwaysOn />
    </Filter>
);

export const EndpointsList = props => (
    <List filters={<EndpointsFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="account_id" reference="accounts">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="from" />
            <TextField source="from_id" label="From ID" />
            <UrlField source="to_url" />
            <BooleanField source="active" />
            <DateField source="createdAt" />
        </Datagrid>
    </List>
);

export const EndpointsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Account" source="account_id" reference="accounts">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="from" choices={endpoints_list} optionText="name" optionValue="value" />
            <TextInput source="from_id" label="From ID" />
            <TextInput source="to_url" type="url" />
            <BooleanInput source="active" initialValue={true} />
            <BooleanInput source="debug" />
        </SimpleForm>
    </Create>
);

export const EndpointsEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Account" source="account_id" reference="accounts">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="from" choices={endpoints_list} optionText="name" optionValue="value" />
            <TextInput source="from_id" />
            <TextInput source="to_url" type="url" />
            <BooleanInput source="active" />
            <BooleanInput source="debug" />
        </SimpleForm>
    </Edit>
);
