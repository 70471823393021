import React from 'react';
import { List, Edit, Filter, Datagrid, TextField, DateField, SimpleForm, TextInput, DateInput } from 'react-admin';

const DebugsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Origin" source="origin" alwaysOn />
    </Filter>
);

export const DebugsList = props => (
    <List filters={<DebugsFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="origin" />
            <TextField source="text" />
            <DateField source="createdAt" />
        </Datagrid>
    </List>
);

export const DebugsEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="name" />
            <TextInput source="text" />
            <DateInput source="createdAt" />
        </SimpleForm>
    </Edit>
);
