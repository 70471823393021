const feathers = require('@feathersjs/feathers');
//import feathers from '@feathersjs/client';
const socketio = require('@feathersjs/socketio-client');
const io = require('socket.io-client');
const auth = require('@feathersjs/authentication-client');

let host = process.env.NODE_ENV === "development" ? "http://localhost:3030" : null;

const socket = io(host);
const app = feathers();

// Set up Socket.io client with the socket
app.configure(socketio(socket, {
    timeout: 3000
}));
app.configure(auth({
    strategy: "jwt",
    storage: window.localStorage,
    storageKey: "accessToken"
}));

app.io.on('disconnect', (reason) => {
    // Show offline message
    console.log('Disconnect', reason);
});

export default app;
